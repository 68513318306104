#load{
    visibility: hidden;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    position: fixed;
    z-index: 9999;
    background: url("../../public/assets/img/ajax-loader.gif") no-repeat center center       rgba(255, 255, 255, 0.6);
}
.loader{
    
        width: 100%;
        height: 100vh;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
    
}
a{
    cursor: pointer;
}
.txtsuccess{color: green;font-weight: bold;}
.txterror{color: red;font-weight: bold;}
.textcenter{text-align: center;font-weight: bold;margin-top: 20px;}
.blue-tab a{background-color: #23525C!important;}
.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  .txtRed{color: red!important;}
  .disableTxt{cursor: not-allowed;pointer-events: none;}